import * as React from 'react';

import Title from '../../Title';

import { useTranslation } from '@external/react-i18next';

const AccessDenied = () => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('default-error-page.403.title', 'Access denied')}</Title>
      <div>
        {t(
          'default-error-page.403.description',
          'You do not have access to the requested page.'
        )}
      </div>
    </>
  );
};

export default AccessDenied;
