import React from 'react';
import RequireLogin from '../components/Auth/RequireLogin';
import { Router } from '@reach/router';
import NotFound from '@components/Routing/NotFound';
import { FEATURE_INVOICE, isEnabled } from '@utils/features';
import DailyClubBalanceReport from '@presenters/web/pages/DailyClubBalanceReport';

const DailyClubBalanceReportPage: React.FC<{
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix } }) => {
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }
  return (
    <RequireLogin>
      <Router basepath={`/${languagePrefix}`}>
        <DailyClubBalanceReport
          path="/daily-club-balance-report/:clubId"
          clubId="is set from path"
        />
      </Router>
    </RequireLogin>
  );
};

export default DailyClubBalanceReportPage;
